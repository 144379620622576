<template>

<div class="embed" v-html="block.embed" />

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock]

}

</script>

<style scoped>

.embed >>> iframe {
	width: 100%;
}

</style>
